import Vue from 'vue'
import VueRouter from 'vue-router'

const home = () => import("./views/Home")
const OurServices = () => import("./views/OurServices")
const Faq = () => import("./views/Faq")
const Concierge = () => import("./views/Concierge")
const PrivacyPolicyNew = () => import("./views/privacyPolicyNew")


const routes = [
    { path: '/', name: 'home', component: home },
    { path: '/our-services', component: OurServices, name: 'OurServices' },
    { path: '/faq', component: Faq, name: 'Faq' },
    { path: '/concierge', component: Concierge, name: 'Concierge' },
    { path: '/privacypolicy', component: PrivacyPolicyNew, name: 'PrivacyPolicyNew' },
]

Vue.use(VueRouter)
const router = new VueRouter({
    mode: 'history',
    routes
})

export default router