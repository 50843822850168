<template>
  <footer class="footer bg__img">
    <div class="container">
      <div class="footer__area section__header__space">
        <div class="row">
          <div class="col-md-6 col-lg-3">
            <div
              class="
                footer__content
                column__space
                wow
                animate__animated animate__fadeInUp
              "
              data-wow-duration="0.8s"
            >
              <a href="index.html" class="logo section__header__space">
                <!-- <img src="@/assets/images/logo.png" alt="Logo" /> -->
                <img src="https://app.shipindia.co/logo.png" alt="Logo" />
              </a>
              <!-- <p class="tertiary wow animate__animated animate__fadeInUp">
                Kaizen logistics pte ltd <br>
                UEN:201512850M <br>
                700 west camp road <br>
                #05-01 <br>
                Singapore 797649
              </p> -->
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div
              class="
                footer__content
                column__space
                wow
                animate__animated animate__fadeInUp
              "
              data-wow-delay="0.1s"
              data-wow-duration="0.8s"
            >
              <h5 class="section__header__space">About Us</h5>
              <div class="footer__content__links">
                <ul>
                  <li>
                    <a href="#" class="secondary">Terms & Conditions</a>
                  </li>
                  <li><a href="#" class="secondary">Privacy Policy</a></li>
                  <li>
                    <a href="#" class="secondary">How we use your data</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div
              class="
                footer__content
                column__space
                wow
                animate__animated animate__fadeInUp
              "
              data-wow-delay="0.2s"
              data-wow-duration="0.8s"
            >
              <h5 class="section__header__space">Policies</h5>
              <div class="footer__content__links">
                <ul>
                  <li>
                    <a href="#" class="secondary">Terms & Conditions</a>
                  </li>
                  <li><a href="#" class="secondary">Privacy Policy</a></li>
                  <li>
                    <a href="#" class="secondary">How we use your data</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div
              class="footer__content wow animate__animated animate__fadeInUp"
              data-wow-delay="0.3s"
              data-wow-duration="0.8s"
            >
              <h5 class="section__header__space">Get In Touch</h5>
              <div class="footer__content__payment content__space">
                <img src="@/assets/images/footer/visa.png" alt="Visa" />
                <img
                  src="@/assets/images/footer/mastercard.png"
                  alt="Mastercard"
                />
                <img src="@/assets/images/footer/express.png" alt="Express" />
              </div>
              <div class="social">
                <a href="https://www.facebook.com/">
                  <i class="fab fa-facebook-f"></i>
                </a>
                <a href="https://www.youtube.com/">
                  <i class="fab fa-youtube"></i>
                </a>
                <a href="https://www.linkedin.com/">
                  <i class="fab fa-linkedin-in"></i>
                </a>
                <a href="https://twitter.com/">
                  <i class="fab fa-twitter"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer__credit">
      <hr />
      <p class="text-center wow animate__animated animate__fadeInUp">
        © ShipIndia 2018 - 2022 | All rights reserved
      </p>
    </div>
  </footer>
</template>
<script>
export default {};
</script>

<style>
</style>
