<template>
  <header
    :class="[
      fixedHeader ? 'header-fixed animate__animated animate__fadeInDown' : '',
      homeRoute ? '' : 'header-secondary',
      mblnav ? 'header-active' : '',
    ]"
  >
    <nav class="navbar navbar-expand-lg">
      <div class="container">
        <a class="navbar-brand" href="index.html">
          <!-- <img src="@/assets/images/logo.png" alt="Logo" class="logo" /> -->
          <img src="https://app.shipindia.co/logo.png" alt="Logo" class="logo" />
        </a>
        <div class="navbar__out d-flex align-items-center order-2 order-lg-3">
          <a href="https://app.shipindia.co/login" class="button d-none d-sm-block"> Log In </a>
          <button
            class="navbar-toggler"
            type="button"
            :class="mblnav ? 'toggle-active' : ''"
            @click="mblnav = !mblnav"
          >
            <span class="icon-bar top-bar"></span>
            <span class="icon-bar middle-bar"></span>
            <span class="icon-bar bottom-bar"></span>
          </button>
        </div>
        <transition name="fadeHeight">
          <div
            class="
              collapse
              navbar-collapse
              justify-content-center
              order-3 order-lg-2
            "
            v-show="mblnav"
            :class="mblnav ? 'show' : ''"
            id="primaryNav"
          >
            <ul class="navbar-nav">
              <li class="nav-item">
                 <router-link to="/" class="nav-link">Home</router-link>
              </li>
              <li class="nav-item">
                 <router-link to="/our-services" class="nav-link">Our Services</router-link>
              </li>
              <!-- <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" id="dropdownService">
                  Our Services
                </a>
                <ul class="dropdown-menu" aria-labelledby="dropdownService">
                  <li><a class="dropdown-item" href="#">Car Shipping</a></li>
                  <li>
                    <a class="dropdown-item" href="#">Ocean Freight Shipping</a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">Air Freight Shipping</a>
                  </li>
                </ul>
              </li> -->
              <li class="nav-item">
                <router-link to="/concierge" class="nav-link">Concierge</router-link>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">Blog</a>
              </li>
              <li class="nav-item">
                <router-link to="/faq" class="nav-link">FAQ</router-link>
              </li>
              <li class="nav-item d-block d-sm-none">
                <a href="contact.html" class="button button--last">Log In</a>
              </li>
            </ul>
          </div>
        </transition>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: "AppHeader",
  data() {
    return {
      mblnav: false,
      fixedHeader: false,
    };
  },
  computed: {
    homeRoute() {
      return this.$route.name == "home";
    },
  },
  created() {
    if (process.browser) {
      window.addEventListener("scroll", this.handleScroll);
    }
  },
  destroyed() {
    if (process.browser) {
      window.removeEventListener("scroll", this.handleScroll);
    }
  },
  methods: {
    handleScroll() {
      const scrollTop = window.scrollY;

      if (scrollTop > 10) {
        this.fixedHeader = true;
      } else {
        this.fixedHeader = false;
      }
    },
  },
};
</script>

<style>
.block {
  display: block !important;
}
</style>
