import Vue from 'vue'
import App from './App'
import router from './router'
//import css
import './assets/vendors/bootstrap/css/bootstrap.min.css'
import './assets/vendors/font-awesome-free/css/all.min.css'
import './assets/vendors/nice-select/css/nice-select.css'
import './assets/vendors/magnific-popup/css/magnific-popup.css'
import './assets/vendors/animate/animate.min.css'
import './assets/vendors/fonts.css'
import './assets/css/style.css'
import './assets/scss/style.scss'

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
