<template>
  <div id="app">
    <!-- ==== preloader start ==== -->
    <div class="preloader" v-if="isLoaded">
      <span>Loading...</span>
    </div>
    <!-- ==== #preloader end ==== -->
      <!-- ==== topbar start ==== -->
    <div class="topbar d-none d-lg-block" v-if="homeRoute==false">
        <div class="container">
            <div class="topbar__area">
                <p class="text-center">Start Shipping with ShipIndia Australia is Here. For more info, <a href="#">Click Here</a></p>
            </div>
        </div>
    </div>
    <!-- ==== #topbar end ==== -->
    <app-header />
    <router-view />
    <app-footer />
  </div>
</template>

<script>
import AppFooter from "./components/AppFooter.vue";
import AppHeader from "./components/AppHeader.vue";
export default {
  name: "AppRoot",
  components: { AppHeader, AppFooter },
  data() {
    return {
      isLoaded: true,
    };
  },
  computed: {
    homeRoute() {
        return this.$route.name=='home';
    }
},
  mounted() {
    document.onreadystatechange = () => {
      if (document.readyState == "complete") {
        this.isLoaded = false;
      }
    };
  },
};
</script>

<style>
</style>
